
// @ts-nocheck
import Vue from "vue";

import IvrResultLink from "@/components/IvrResult/Link.vue";
import IvrResultTreeEnd from "@/components/IvrResult/TreeEnd.vue";

export default Vue.extend({
  name: "IvrResultItem",
  components: {
    IvrResultLink,
    IvrResultTreeEnd,
  },
  props: {
    dial: String,
    expandAll: Boolean,
    filterInput: String,
    item: Object,
    optionsTree: Array,
  },
});
