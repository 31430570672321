
// @ts-nocheck
import { Modal } from "@num/component-library";
import Vue from "vue";

export default Vue.extend({
  name: "IvrConfirmDialModal",
  components: {
    Modal,
  },
  props: {
    href: String,
  },
  methods: {
    dialNumber() {
      window.open(this.href, "_self");
      this.$emit("close");
    },
  },
});
