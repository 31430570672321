
// @ts-nocheck
import Vue from "vue";

export default Vue.extend({
  name: "IvrResultOptionNumber",
  props: {
    number: Number,
  },
});
