
// @ts-nocheck
import Vue from "vue";

import Highlightable from "@/components/Highlightable.vue";
import IvrConfirmDialModal from "@/components/IvrResult/ConfirmDialModal.vue";
import IvrResultOptionNumber from "@/components/IvrResult/OptionNumber.vue";

export default Vue.extend({
  name: "IvrResultTreeEnd",
  components: {
    Highlightable,
    IvrConfirmDialModal,
    IvrResultOptionNumber,
  },
  props: {
    dial: String,
    filterInput: String,
    item: Object,
    optionsTree: Array,
  },
  data() {
    return {
      showConfirmDialModal: false,
    };
  },
  computed: {
    hrefWithOptions() {
      return `tel:${this.dial}${this.optionsString}`;
    },
    optionsString() {
      return this.optionsTree.reduce((acc, o) => {
        if (o.option) {
          const commas = ",".repeat(Math.ceil(o.wait / 2000));
          return (acc += `${commas}${o.option}`);
        } else {
          return acc;
        }
      }, "");
    },
  },
});
