
// @ts-nocheck
import Vue from "vue";

import Highlightable from "@/components/Highlightable.vue";
import IvrResultOptionNumber from "@/components/IvrResult/OptionNumber.vue";

import { getMenuTreeEndsFromItem } from "@/utils/utils";

export default Vue.extend({
  name: "IvrResultLink",
  components: {
    Highlightable,
    IvrResultMenu: () => import("@/components/IvrResult/Menu.vue"),
    IvrResultOptionNumber,
  },
  props: {
    dial: String,
    expandAll: Boolean,
    filterInput: String,
    item: Object,
    optionsTree: Array,
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    treeEndCount() {
      return getMenuTreeEndsFromItem(this.item);
    },
  },
  watch: {
    expandAll: {
      handler(newVal) {
        this.expanded = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },
});
