
// @ts-nocheck
import Vue from "vue";

import IvrResultItem from "@/components/IvrResult/Item.vue";

export default Vue.extend({
  name: "IvrResultMenu",
  components: {
    IvrResultItem,
  },
  props: {
    dial: String,
    expandAll: Boolean,
    filterInput: String,
    menu: Array,
    optionsTree: Array,
  },
});
